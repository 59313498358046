import { $http } from '@/service/interceptor';
import { Toast } from 'vant';

export const $getWxOpenId = async code => {
    try {
        const res = await $http.post(
            `/EnterpriseInvitation/login/wx/openId/${code}`,
            {},
            {
                headers: {
                    auth: false
                }
            }
        );
        return res.data.data;
    } catch (error) {
        return false;
    }
};
export const $getAppId = async () => {
    try {
        const res = await $http.get('/EnterpriseInvitation/login/wx/appid', {
            headers: {
                auth: false
            }
        });
        return res.data.data;
    } catch (error) {
        return false;
    }
};
export const $getLoginCode = async mobile => {
    try {
        const res = await $http.get(`/sms/ei/loginCode/${mobile}`, {
            headers: {
                auth: false
            }
        });
        Toast(res.data.message);
        return res.data.success;
    } catch (error) {
        return false;
    }
};
export const $jobNoLogin = async params => {
    try {
        const res = await $http.post(
            '/EnterpriseInvitation/login/agent/jobNO/login',
            params,
            {
                headers: {
                    auth: false,
                    ignoreStatus: true
                }
            }
        );
        return res.data;
    } catch (error) {
        return false;
    }
};
export const $mobileLogin = async params => {
    try {
        const res = await $http.post(
            '/EnterpriseInvitation/login/agent/mobile/login',
            params,
            {
                headers: {
                    auth: false,
                    ignoreStatus: true
                }
            }
        );
        return res.data;
    } catch (error) {
        return false;
    }
};
export const $getActiveList = async params => {
    try {
        const res = await $http.get('/EnterpriseInvitation/page', { params });
        return res.data.data;
    } catch (error) {
        return false;
    }
};
export const $getInviteActiveSignUpList = async params => {
    try {
        const res = await $http.get(
            '/EnterpriseInvitation/enroll/invitation/page',
            {
                params
            }
        );
        return res.data.data;
    } catch (error) {
        return false;
    }
};
export const $getActiveSignUpList = async params => {
    try {
        const res = await $http.get('/EnterpriseInvitation/enroll/page', {
            params
        });
        return res.data.data;
    } catch (error) {
        return false;
    }
};
export const $getActiveDetail = async id => {
    try {
        const res = await $http.get(`/EnterpriseInvitation/info/${id}`);
        return res.data.data;
    } catch (error) {
        return false;
    }
};
export const $getActiveDetailByAdminCode = async code => {
    try {
        const res = await $http.get(
            `/EnterpriseInvitation/anonymous/bycode/${code}`,
            {
                headers: {
                    auth: false
                }
            }
        );
        return res.data.data;
    } catch (error) {
        return false;
    }
};
export const $getActiveDetailByCode = async code => {
    try {
        const res = await $http.get(
            `/EnterpriseInvitation/invite/bycode/${code}`,
            {
                headers: {
                    auth: false
                }
            }
        );
        return res.data.data;
    } catch (error) {
        return false;
    }
};
export const $getActiveShareCode = async id => {
    try {
        const res = await $http.get(`/EnterpriseInvitation/invite/code/${id}`);
        return res.data.data;
    } catch (error) {
        return false;
    }
};
export const $submitSignUp = async params => {
    try {
        const res = await $http.post(
            '/EnterpriseInvitation/enroll/add',
            params,
            {
                headers: {
                    auth: false,
                    ignoreStatus: true
                }
            }
        );
        return res.data;
    } catch (error) {
        return false;
    }
};
export const $loginOut = async () => {
    try {
        const res = await $http.post('/EnterpriseInvitation/user/logout');
        return true;
    } catch (error) {
        return false;
    }
};
export const $changePwd = async params => {
    try {
        const res = await $http.post(
            '/EnterpriseInvitation/user/modifyPassword',
            params
        );
        return true;
    } catch (error) {
        return false;
    }
};
export const $getSignature = async params => {
    try {
        const res = await $http.post(
            '/EnterpriseInvitation/login/wx/js/signature',
            params,
            {
                headers: {
                    auth: false
                }
            }
        );
        return res.data.data;
    } catch (error) {
        return false;
    }
};
export const $getUserInfo = async () => {
    try {
        const res = await $http.get('/EnterpriseInvitation/user/info');
        return res.data.data;
    } catch (error) {
        return false;
    }
};
