import axios from 'axios';
import httpConfig from '../environments';

import { Toast } from 'vant';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

axios.defaults.timeout = 30000; // 设置超时时间
const createInterceptor = instance => {
    instance.interceptors.request.use(
        config => {
            // 判断接口是否需要token
            if (config.headers.auth) {
                // 如果没有token 拒绝请求
                const stroe = useStore();
                let token = stroe.token;
                if (!token) {
                    Toast('登录失效，请重新登录');
                    store.updateToken('');
                    const router = useRouter();
                    router.replace('/login');
                    Promise.reject(config);
                }
                config.headers.Authorization = token;
            }
            return config;
        },
        error => {
            // Do something with request error
            Toast('请求错误，请稍后再试');
            Promise.reject(error);
        }
    );
    // Add a response interceptor
    instance.interceptors.response.use(
        response => {
            // Do something with response data
            if (response.config.headers.ignoreStatus) {
                if (response.status !== 200) {
                    Toast(response.data.message || '响应错误，请稍后再试');
                    return Promise.reject(response);
                }
                return response;
            }
            if (response.data.status !== 200) {
                Toast(response.data.message || '响应错误，请稍后再试');
                return Promise.reject(response);
            }
            return response;
        },
        error => {
            // Do something with response error
            Toast('网络错误，请稍后再试');
            return Promise.reject(error);
        }
    );
};
// 初始化实例
const httpInstance = axios.create({
    baseURL: httpConfig.url + '/yunaibao',
    headers: {
        auth: true
    }
});
createInterceptor(httpInstance);
export const $http = httpInstance;
