import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import { useStore } from '@/store';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const app = createApp(App);
app.use(router);
app.use(pinia);
const store = useStore();
store.getSignature();
app.mount('#app');
export default app;
// createApp(App).use(router).use(pinia).mount('#app');
