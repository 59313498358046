import { defineStore } from 'pinia';
import { ref } from 'vue';
import { $getSignature, $getWxOpenId } from '@/service';

export const useStore = defineStore(
    'yunibao',
    () => {
        let currentTabBar = ref(0);

        let openId = ref('');

        let token = ref('');

        let defaultPassword = ref(false);

        function changeTabBar(index) {
            let title = {
                0: '活动',
                1: '报名记录',
                2: '我的'
            };
            document.title = title[index];
            currentTabBar.value = index;
        }

        function saveOpenId(id) {
            openId.value = id;
        }

        function updateToken(data) {
            token.value = data;
        }

        function saveIsDefaultPwd(data) {
            defaultPassword.value = data;
        }

        function getQueryString(name) {
            let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
            let r = window.location.search.substr(1).match(reg);
            if (r != null) {
                return unescape(r[2]);
            }
            return null;
        }

        function isWxBrowser() {
            const ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf('micromessenger') != -1) {
                return true;
            } else {
                return false;
            }
        }
        async function getWxOpenId(code) {
            const data = await $getWxOpenId(code);
            if (data) {
                saveOpenId(data);
            }
        }
        async function getSignature() {
            let url = window.location.href;
            const data = await $getSignature({ url });
            wx.config({
                debug: process.env.NODE_ENV === 'development', // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
                appId: data.appId, // 必填，公众号的唯一标识
                timestamp: Number(data.timestamp), // 必填，生成签名的时间戳
                nonceStr: data.noncestr, // 必填，生成签名的随机串
                signature: data.signature, // 必填，签名
                jsApiList: [
                    'updateAppMessageShareData',
                    'openLocation',
                    'closeWindow'
                ] // 必填，需要使用的 JS 接口列表
            });
        }

        return {
            currentTabBar,
            changeTabBar,
            saveOpenId,
            updateToken,
            getSignature,
            openId,
            token,
            saveIsDefaultPwd,
            defaultPassword,
            getQueryString,
            isWxBrowser,
            getWxOpenId
        };
    },
    {
        persist: {
            paths: ['openId', 'token', 'defaultPassword'],
            storage: localStorage
        }
    }
);
