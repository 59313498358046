const env = process.env.NODE_ENV;
console.log(env);
const configs = {
    // 生产环境
    production: {
        url: '/api'
    },
    // 开发环境
    development: {
        url: '/api'
    }
};
export default configs[env];
